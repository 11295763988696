import React, { useState } from 'react';
import { FaShareAlt } from "react-icons/fa"; // Import share icon

const ShareButton = ({ shareData }) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const handleNativeShare = async () => {
    if (navigator.canShare && navigator.canShare(shareData)) {
      try {
        await navigator.share(shareData);
        console.log('Content shared successfully!');
      } catch (error) {
        setErrorMessage('Error sharing content: ' + error.message);
      }
    } else {
      setErrorMessage('Web Share API is not supported on this browser or device.');
    }
  };

  const handleFallbackShare = async () => {
    try {
      await navigator.clipboard.writeText(shareData.url);
      setErrorMessage('Link copied to clipboard!');
    } catch (error) {
      setErrorMessage('Failed to copy link: ' + error.message);
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      handleNativeShare();
    } else {
      handleFallbackShare();
    }
  };

  return (
    <div>
      {/* Share Icon instead of button */}
      <FaShareAlt onClick={handleShare} style={{ cursor: 'pointer', fontSize: '24px' }} />
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
};

export default ShareButton;
