import React from "react";
import { ScaleLoader } from "react-spinners";

const LoadingComponent = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0)", // Adjust opacity as needed
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999, // Ensure it's on top of everything
      }}
    >
      <div>
        <div style={{ fontSize: 24, fontWeight: "bold", marginBottom: 16, color: "#ffffff" }}>
          Loading...
        </div>
        {/* Center the loading spinner horizontally and vertically */}
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ScaleLoader color="#ffffff" />
        </div>
      </div>
    </div>
  );
};

export default LoadingComponent;
