import React from "react";

function Layout({ children, athleteData }) {
  console.log("LAYOUT DATA: ", athleteData);

  // Function to handle video error
  const handleVideoError = () => {
    console.error("Error loading video from URL:", athleteData.backgroundVideogetUrl);
  };

  return (
    <>
      {athleteData.backgroundVideoUrl ? (
        <div className="video-container">
          <video playsInline autoPlay muted loop className="background-video">
            <source src={athleteData.backgroundVideoUrl} type="video/mp4" />
            Your browser does not support the video.
          </video>
          {/* Your other content goes here */}
        </div>
      ) : athleteData.backgroundBrandingUrl ? (
        <div className="video-container" style={{ backgroundImage: `url(${athleteData.backgroundBrandingUrl})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", filter: 'grayscale(50%)', opacity: 0.7 }}>
          {/* Your other content goes here */}
        </div>
      ) : null}

      <div className="site-wrapper">
        <main className="content float-right">
          {children}
        </main>
      </div>
    </>
  );
}

export default Layout;
