import React from "react";

function SectionHeading({ title, spacer, color }) {
  return (
    <div style={{textAlign:'center'}}>
      <h3 style={{color:color, textDecoration:"underline"}} className="section-title">{title}</h3>
      <div style={{height:{spacer}}}>&nbsp;</div>
    </div>
  );
}

export default SectionHeading;
