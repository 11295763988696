import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const ContactForm = ({ email, cardBackgroundColor, textColor }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [content, setContent] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [captchaVisible, setCaptchaVisible] = useState(false); // State for captcha visibility
  const [captchaToken, setCaptchaToken] = useState(null); // State to hold the captcha token

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if captcha is visible and solved
    if (!captchaVisible) {
      setCaptchaVisible(true); // Show the captcha when the form is submitted
      return; // Exit the function until captcha is solved
    }

    if (!captchaToken) {
      setErrorMessage("Please complete the CAPTCHA");
      return;
    }

    const contactusData = {
      firstName,
      lastName,
      email,
      phone,
      content,
      date: new Date().toISOString(),
      approved: false,
    };

    try {
      const response = await axios.post(
        "https://izc2zxpn3jg2ug4u2jhnzdktfa0lxgsh.lambda-url.us-west-2.on.aws/",
        {
          email,
          lead: contactusData,
          captchaToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );

      if (response.status === 200) {
        setSuccessMessage("Successful Submission!");
        setFirstName("");
        setLastName("");
        setContent("");
        setPhone("");
        setShowForm(false); // Hide form on successful submission
        setCaptchaToken(null); // Reset captcha token
        setCaptchaVisible(false); // Hide captcha
      } else {
        setErrorMessage("Failed to submit, please try again.");
      }
    } catch (error) {
      console.error("Error submitting contact form:", error);
      setErrorMessage("Failed to submit, please try again.");
    }
  };

  const handleToggleForm = () => {
    if (showForm) {
      setFirstName("");
      setLastName("");
      setContent("");
      setPhone("");
    }
    setShowForm((prev) => !prev);
    setCaptchaVisible(false); // Reset captcha visibility on form toggle
  };

  const handleCaptchaChange = (value) => {
    setCaptchaToken(value); // Set the captcha token when solved
    setErrorMessage(""); // Clear any captcha-related error messages
  };

  return (
    <div>
      <button className="btn btn-border-light btn-lg" onClick={handleToggleForm}>
        {showForm ? "Cancel Contact Us" : "Contact Us"}
      </button>
      <section style={{ textAlign: "center", padding: 10 }}>
        {showForm && (
          <form className="form-group" onSubmit={handleSubmit}>
            <div style={{ marginTop: 20 }}>
              <input
                type="text"
                id="firstName"
                placeholder="First Name"
                value={firstName}
                className="form-control"
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <input
                type="text"
                id="lastName"
                placeholder="Last Name"
                value={lastName}
                className="form-control"
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <input
                type="tel"
                id="phone"
                placeholder="Phone"
                value={phone}
                className="form-control"
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <textarea
                id="content"
                rows={5}
                style={{ resize: "none" }}
                value={content}
                placeholder="Your Message"
                className="form-control"
                onChange={(e) => setContent(e.target.value)}
                required
              />
            </div>
            {captchaVisible && (
              <div style={{ marginTop: 10 }}>
                <ReCAPTCHA
                  sitekey="6LdOFzYqAAAAAN2KsrsyI6Ye3tmXPFjoWS1WUOHi" // Replace with your actual site key
                  onChange={handleCaptchaChange}
                />
              </div>
            )}
            <div style={{ marginTop: 10 }}>
              <button className="btn btn-lg" style={{ backgroundColor: cardBackgroundColor, color: textColor }} type="submit">
                Submit
              </button>
            </div>
          </form>
        )}
        {successMessage && <p>{successMessage}</p>}
        {errorMessage && <p>{errorMessage}</p>}
      </section>
    </div>
  );
};

export default ContactForm;
