import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const ReviewForm = ({ email, cardBackgroundColor, textColor, mynarrativedata }) => {
  const [name, setName] = useState("");
  const [review, setReview] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [captchaVisible, setCaptchaVisible] = useState(false); // State for captcha visibility
  const [captchaToken, setCaptchaToken] = useState(null); // State to hold the captcha token

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if captcha is visible and solved
    if (!captchaVisible) {
      setCaptchaVisible(true); // Show the captcha when the form is submitted
      return; // Exit the function until captcha is solved
    }

    if (!captchaToken) {
      setErrorMessage("Please complete the CAPTCHA");
      return;
    }

    const reviewData = {
      name,
      review,
      date: new Date().toISOString(),
      approved: false,
    };

    try {
      console.log(`Email => ${email}`);
      console.log(`Review Data => ${JSON.stringify(reviewData)}`);
      console.log("Sending request with Axios");

      const response = await axios.post(
        "https://xh6cmf6eexrnrkq4ejrqsb5m4u0dwyqr.lambda-url.us-west-2.on.aws/",
        {
          email,
          review: reviewData,
          captchaToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
          }
        }
      );

      console.log("Response status:", response.status);
      console.log("Response data:", response.data);

      if (response.status === 200) {
        setSuccessMessage("Review submitted successfully!");
        setName("");
        setReview("");
        setShowForm(false); // Hide form on successful submission
        setCaptchaToken(null); // Reset captcha token
        setCaptchaVisible(false); // Hide captcha
      } else {
        setErrorMessage("Failed to submit, please try again.");
      }
    } catch (error) {
      console.error("Error submitting review:", error);
      setErrorMessage("Failed to submit, please try again.");
    }
  };

  const handleToggleForm = () => {
    if (showForm) {
      setName("");
      setReview("");
    }
    setShowForm((prev) => !prev);
    setCaptchaVisible(false); // Reset captcha visibility on form toggle
  };

  const handleCaptchaChange = (value) => {
    setCaptchaToken(value); // Set the captcha token when solved
    setErrorMessage(""); // Clear any captcha-related error messages
  };

  return (
    <div>
      <button className="btn btn-border-light btn-lg" onClick={handleToggleForm}>
        {showForm ? "Cancel Review" : "Leave A Review"}
      </button>
      <section style={{ textAlign: "center", padding: 10 }}>
        {showForm && (
          <form className="form-group" onSubmit={handleSubmit}>
            <div style={{ marginTop: 20 }}>
              <input
                type="text"
                id="name"
                placeholder="Your Name"
                value={name}
                className="form-control"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <textarea
                id="review"
                rows={5}
                style={{ resize: "none" }}
                value={review}
                placeholder="Your Review"
                className="form-control"
                onChange={(e) => setReview(e.target.value)}
                required
              />
            </div>
            {captchaVisible && (
              <div style={{ marginTop: 10 }}>
                <ReCAPTCHA
                  sitekey="6LdOFzYqAAAAAN2KsrsyI6Ye3tmXPFjoWS1WUOHi" // Replace with your actual site key
                  onChange={handleCaptchaChange}
                />
              </div>
            )}
            <div style={{ marginTop: 10 }}>
              <button className="btn btn-lg" style={{ backgroundColor: cardBackgroundColor, color: textColor }} type="submit">
                Submit
              </button>
            </div>
          </form>
        )}
        {successMessage && <p>{successMessage}</p>}
        {errorMessage && <p>{errorMessage}</p>}
      </section>
    </div>
  );
};

export default ReviewForm;
