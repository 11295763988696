import React from "react";

export const StatsLinks = ({ partnerLogo1, partnerLogo2, partnerLogo3, partnerLogo4, partnerTitle1, partnerTitle2, partnerTitle3,partnerTitle4, partnerUrl1, partnerUrl2, partnerUrl3, partnerUrl4, cardColor, fontColor }) => {
  return (
    <div class="row">
  {partnerLogo1 && (
    <div class="col-6 mt-20">
      <a href={partnerUrl1} target="_blank">
        <div class="service-item text-center" style={{ backgroundColor: cardColor, border: "#000" }}>
          <img src={partnerLogo1} width={80} />
        </div>
      </a>
      <h3 style={{color:fontColor, fontSize:18, fontWeight:200}} class="text-center">{partnerTitle1}</h3>
    </div>
  )}
  {partnerLogo2 && (
    <div class="col-6 mt-20">
      <a href={partnerUrl2} target="_blank">
        <div class="service-item text-center" style={{ backgroundColor: cardColor, border: "#000" }}>
          <img src={partnerLogo2} width={80} />
        </div>
      </a>
      <h3 style={{color:fontColor, fontSize:18, fontWeight:200}} class="text-center">{partnerTitle2}</h3>
    </div>
  )}
  {partnerLogo3 && (
    <div class="col-6 mt-20">
      <a href={partnerUrl3} target="_blank">
        <div class="service-item text-center" style={{ backgroundColor: cardColor, border: "#000" }}>
          <img src={partnerLogo3} width={80} />
        </div>
      </a>
      <h3 style={{color:fontColor, fontSize:18, fontWeight:200}} class="text-center">{partnerTitle3}</h3>
    </div>
  )}
  {partnerLogo4 && (
    <div class="col-6 mt-20">
      <a href={partnerUrl4} target="_blank">
        <div class="service-item text-center" style={{ backgroundColor: cardColor, border: "#000" }}>
          <img src={partnerLogo4} width={80} />
        </div>
      </a>
      <h3 style={{color:fontColor, fontSize:18, fontWeight:200}} class="text-center">{partnerTitle4}</h3>
    </div>
  )}
</div>

  );
}

export const AdditionalStats = ({ additionalLink, additionalLinkTitle, merchTitle, merchLink, cardColor }) => {
  return (
    <div className="row -mt-20">
      {additionalLink && (
        <div className="col-md-5 col-sm-5 mt-20">
          <div className="service-item text-center" style={{ backgroundColor: cardColor, border: "#000" }}>
            <a href={additionalLink} target="_blank">
              <h4 style={{ fontSize: 14, color: "#000", fontWeight: "bold", textAlign: "center" }}>{additionalLinkTitle}</h4>
            </a>
          </div>
        </div>
      )}
      {merchLink && (
        <div className="col-md-5 col-sm-5 mt-20">
          <div className="service-item text-center" style={{ backgroundColor: cardColor, border: "#000" }}>
            <a href={merchLink} target="_blank">
              <h4 style={{ fontSize: 14, color: "#000", fontWeight: "bold", textAlign: "center" }}>{merchTitle}</h4>
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export const NewsArticleLinks = ({ newsArticleLink1, newsArticleLink2, newsArticleLink3, cardColor  }) => {
  return (
    <div className="row -mt-20">
      {newsArticleLink1 && (
        <div className="col-md-5 col-sm-5 mt-20">
          <div className="service-item text-center" style={{ backgroundColor: cardColor, border: "#000" }}>
            <a href={newsArticleLink1} target="_blank">
              <h4 style={{ fontSize: 14, color: "#000", fontWeight: "bold", textAlign: "center" }}>News Article 1 ⚡</h4>
            </a>
          </div>
        </div>
      )}
      {newsArticleLink2 && (
        <div className="col-md-5 col-sm-5 mt-20">
          <div className="service-item text-center" style={{ backgroundColor: cardColor, border: "#000" }}>
            <a href={newsArticleLink2} target="_blank">
              <h4 style={{ fontSize: 14, color: "#000", fontWeight: "bold", textAlign: "center" }}>News Article 2 🔥</h4>
            </a>
          </div>
        </div>
      )}
      {newsArticleLink3 && (
        <div className="col-md-5 col-sm-5 mt-20">
          <div className="service-item text-center" style={{ backgroundColor: cardColor, border: "#000" }}>
            <a href={newsArticleLink3} target="_blank">
              <h4 style={{ fontSize: 14, color: "#000", fontWeight: "bold", textAlign: "center" }}>News Article 3 ✨</h4>
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
