import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import Layout from "../components/Layout/Layout";
import About from "../components/Sections/About";
import Herosection from "../components/Sections/Herosection";
import SectionHeading from "../components/Items/SectionHeading";
import LoadingComponent from "../components/Items/LoadingComponent";
import { AdditionalStats, StatsLinks, NewsArticleLinks } from "../components/Sections/Services";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DOMPurify from 'dompurify';
import { TikTokEmbed, TwitterEmbed, YouTubeEmbed } from "react-social-media-embed";
import LogoComponent from "../components/Items/Logo";
import ServicesCarousel from "../components/Items/ServicesCarousel";
import ReviewForm from "../components/Items/ReviewForm";
import ContactForm from "../components/Items/ContactForm";
import { useParams } from "react-router-dom";
import ShareButton from "../components/Share/Share";

function Homepage() {
  const headerData = {
    imageThumb: "/images/logo.png",
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  const [error, setError] = useState('');
  const [username, setUsername] = useState('');
  const [athleteData, setAthleteData] = useState({}); // Use a more specific type if available
  const [tikTokUrl, setTikTokUrl] = useState('');
  const [spotifyEmbed, setSpotifyEmbed] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const { username: pathUsername } = useParams();
  useEffect(() => {
    // Use window.location to get the current URL
    const currentPath = window.location.pathname;
    const pathSegment = currentPath.substring(1); // Remove the leading slash
    console.log(pathSegment)
  })


  useEffect(() => {
    // Extract the username from the query string
    const queryParams = new URLSearchParams(window.location.search);
    const usernameParam = queryParams.get('username');

    // Prefer the query param username, otherwise fall back to the path username
    const username = usernameParam || pathUsername;

    const fetchDataAsync = async () => {
      setIsLoading(true);
      setError(null);

      try {
        console.log("Fetching data for:", username);

        // If the username is from query parameters, use the old fetch URL
        const fetchUrl = usernameParam
          ? `https://l2ikmzaoichzlu6pd4gkbj5nmy0mmkjs.lambda-url.us-west-2.on.aws/?email=${username}`
          : `https://rdn2fn7gvnfpxmoqho44xcfbq40exaqm.lambda-url.us-west-2.on.aws/?username=${username}`;

        const response = await fetch(fetchUrl);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log("Data received:", data[0]);
        setAthleteData(data[0]);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (username) {
      fetchDataAsync();
    }

    window.scrollTo(0, 0); // Ensure page scrolls to top when component mounts
  }, [pathUsername]); // Add pathUsername as a dependency to ensure it runs when path changes

  useEffect(() => {
    const fetchLongUrl = async (shortUrl) => {
      try {
        const response = await fetch(`https://www.tiktok.com/oembed?url=${shortUrl}`);
        const data = await response.json();
        const parser = new DOMParser();
        const doc = parser.parseFromString(data.html, 'text/html');
        const longUrl = doc.querySelector('blockquote').getAttribute('cite');

        setTikTokUrl(longUrl);
        console.log("tikTokUrl: ", tikTokUrl)
      } catch (error) {
        console.error('Error fetching TikTok long URL:', error);
      }
    };

    if (athleteData?.tiktokEmbeddedPostLink && athleteData.tiktokEmbeddedPostLink.includes("https://www.tiktok.com/t/")) {
      fetchLongUrl(athleteData.tiktokEmbeddedPostLink);
    }
    else {
      setTikTokUrl(athleteData.tiktokEmbeddedPostLink)
    }
  }, [athleteData]);

  useEffect(() => {
    const fetchSpotifyEmbed = async (spotifyUrl) => {
      try {
        const response = await fetch(`https://open.spotify.com/oembed?url=${spotifyUrl}`);
        const data = await response.json();
        setSpotifyEmbed(data.html);
      } catch (error) {
        console.error("Error fetching Spotify Embed:", error);
      }
    };

    if (athleteData?.spotifySong) {
      if (athleteData.spotifySong.includes("<iframe")) {
        setSpotifyEmbed(athleteData.spotifySong);
      } else {
        fetchSpotifyEmbed(athleteData.spotifySong);
      }
    }
  }, [athleteData?.spotifySong]);

  useEffect(() => {
    if (spotifyEmbed) {
      const updatedEmbed = spotifyEmbed.replace(
        /height="[^"]*"/,
        'height="500"' // Set your desired height here
      );
      setSpotifyEmbed(updatedEmbed);
    }
  }, [spotifyEmbed]);

  const hexToRgb = (hex) => {
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  // Convert hex to RGB and apply opacity
  const bgColorRgb = athleteData?.profileColor ? hexToRgb(athleteData?.profileColor) : null;
  const finalBackgroundColor = bgColorRgb
    ? `rgba(${bgColorRgb.r}, ${bgColorRgb.g}, ${bgColorRgb.b}, 0.9)`
    : "rgba(48, 48, 48, 0.9)";
  const $cardBackgroundColor = athleteData.profileColor ? `${athleteData.profileColor}` : "#51ABFF";
  const $primaryFontColor = athleteData.primaryFontColor ? `${athleteData.primaryFontColor}` : "#FFFFFF";
  const $secondaryFontColor = athleteData.secondaryFontColor ? `${athleteData.secondaryFontColor}` : "#FFFFFF";
  const $secondaryCardColor = athleteData.secondaryCardColor ? `${athleteData.secondaryCardColor}` : "#FFFFFF";
  const $generalTextColor = athleteData.generalTextColor ? `${athleteData.generalTextColor}` : "#000000";
  const $logoBackgroundColor = athleteData.logoBackgroundColor ? `${athleteData.logoBackgroundColor}` : "#252f41"
  const shareData = {
    title: `Radar Page`,
    text: 'This was built using My Narrative',
    url: window.location.href, // Share the current page URL
  };
  return (
    <>
      {isLoading && <LoadingComponent />}
      <Layout athleteData={athleteData}>
        <ShareButton shareData={shareData} />
        <Element name="section-home">
          <Herosection
            businessName={athleteData.businessName}
            primaryFontColor={$primaryFontColor}
            businessLogo={athleteData.businessLogo}
            description={athleteData.businessSloganMission}
            businessAddress={athleteData.businessAddress}
            businessAddress1={athleteData.businessAddressAdditional}
            facebookUrl={athleteData.facebookUrl}
            businessEmail={athleteData.businessEmail}
            businessPhone={athleteData.businessPhone}
            instagramProfileUrl={athleteData.instagramProfileUrl}
            tiktokProfileUrl={athleteData.tiktokProfileUrl}
            twitterUrl={athleteData.twitterUrl}
            backgroundColor={$logoBackgroundColor}
          />
        </Element>
        <Element name="section-skills">
          <a href={athleteData.businessSiteUrl} target="_blank">
            <div className="row" style={{ backgroundColor: $cardBackgroundColor, padding: 0, borderRadius: 10, opacity: 0.8 }}>
              <div className="col-md-4 col-sm-4 col-6 align-items-center" style={{ padding: 0, alignContent: "center", marginTop: 0 }}>
                <div className="service-item" style={{ backgroundColor: $secondaryCardColor, border: "#000", padding: '10px' }}>
                  <LogoComponent
                    backgroundColor={$logoBackgroundColor}
                    businessLogo={athleteData.businessLogo}
                  />
                </div>
              </div>
              <div className="col-md-8 col-sm-8 col-6 align-items-center" style={{ padding: 0, alignContent: "center", borderRadius: 10 }}>
                <div className="text-center">
                  <h3 style={{ margin: '10px', fontSize: '1.2rem', color: $secondaryFontColor, alignContent: "center" }}>OUR WEBSITE</h3>
                </div>
                <div className="text-center">
                  <p style={{ fontSize: 16, margin: '5px 0', color: $generalTextColor }}>{athleteData.businessCallToAction}</p>
                </div>
              </div>
            </div>
          </a>
        </Element>

        <Element name="section-skills">
          <section className=" padding-20">
            <SectionHeading color={$primaryFontColor} title="SERVICES" />
            <ServicesCarousel
              athleteData={athleteData}
              cardBackgroundColor={$cardBackgroundColor}
              generalTextColor={$generalTextColor}
              secondaryFontColor={$secondaryFontColor}
            />
          </section>
        </Element>
        {
          tikTokUrl ? (
            <Element name="section-skills">
              <section className="padding-20">
                <TikTokEmbed url={tikTokUrl} width={325} style={{ margin: "0 auto" }} />
              </section>
            </Element>
          ) : (<></>)
        }
        <Element name="section-skills">
          <section className=" padding-20">
            <SectionHeading color={$primaryFontColor} title="ABOUT US" />
            <div className="col-md-8 col-sm-8 mt-20">
              <div className="card" style={{
                borderRadius: 20,
                overflow: 'hidden',
                backgroundColor: $cardBackgroundColor,
                opacity: 0.8
              }}>
                <div style={{
                  backgroundImage: `url(${athleteData.aboutUsImageUrl})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  height: '200px', // Adjust the height as needed

                  borderBottomLeftRadius: 20,
                  borderBottomRightRadius: 20
                }}>
                  {/* Empty div for the background image */}
                </div>
                <div style={{
                  padding: 20,
                  borderBottomLeftRadius: 20,
                  borderBottomRightRadius: 20,
                  textAlign: 'center'
                }}>
                  <h3 style={{ color: $secondaryFontColor, textTransform: "uppercase", marginBottom: 5 }}>{athleteData.businessName}</h3>
                  <div style={{}} className="text-center">
                    <p style={{ fontSize: 12, color: $primaryFontColor }}><a style={{ color: $primaryFontColor }} href={`tel:${athleteData.businessPhone}`}>{athleteData.businessPhone}</a> - <a style={{ color: $primaryFontColor }} href={`mailto:${athleteData.businessEmail}`}>{athleteData.businessEmail}</a></p>
                  </div>
                  <p style={{ color: $generalTextColor }}>{athleteData.businessDescription}</p>
                </div>
              </div>
            </div>
          </section>
        </Element>
        {
          athleteData.googleReviewUrl ? (<Element name="section-skills">
            <a href={athleteData.googleReviewUrl} target="_blank">
              <div className="row" style={{ backgroundColor: $cardBackgroundColor, padding: 0, borderRadius: 10, marginBottom: 20, opacity: 0.8 }}>

                <div className="col-md-4 col-sm-4" style={{ padding: 0 }}>
                  <div className="service-item text-center" style={{ backgroundColor: $secondaryCardColor, border: $secondaryCardColor }}>

                    <LogoComponent
                      backgroundColor={$logoBackgroundColor}
                      businessLogo={athleteData.businessLogo}
                    />

                  </div>
                </div>
                <div className="col-md-8 col-sm-8 align-items-center" style={{ padding: 0, alignContent: "center", borderRadius: 10 }}>
                  <div className="text-center">
                    <h3 style={{ color: $secondaryFontColor }}>GOOGLE REVIEWS</h3>
                  </div>
                  <div className="text-center">
                    <p style={{ fontSize: 14, color: $generalTextColor, paddingLeft: 20, paddingRight: 20 }}>{athleteData.businessDescription}</p>
                  </div>
                </div>
              </div>
            </a>
          </Element>) : (<></>)
        }
        {
          athleteData.additionalReviewUrl ? (
            <Element name="section-skills">
              <a href={athleteData.additionalReviewUrl} target="_blank">
                <div className="row" style={{ backgroundColor: $cardBackgroundColor, padding: 0, borderRadius: 10, marginBottom: 20 }}>
                  <div className="col-md-4 col-sm-4" style={{ padding: 0 }}>
                    <div className="service-item text-center" style={{ backgroundColor: $secondaryCardColor, border: $secondaryCardColor }}>

                      <LogoComponent
                        backgroundColor={$logoBackgroundColor}
                        businessLogo={athleteData.businessLogo}
                      />

                    </div>
                  </div>
                  <div className="col-md-8 col-sm-8 align-items-center" style={{ padding: 0, alignContent: "center", borderRadius: 10 }}>
                    <div className="text-center">
                      <h3 style={{ color: $secondaryFontColor }}>ADDITIONAL REVIEWS</h3>
                    </div>
                  </div>
                </div>
              </a>
            </Element>) : (<></>)
        }
        {
          spotifyEmbed ? (
            <Element name="section-skills">
              <section>
                <div style={{ minHeight: 500 }} dangerouslySetInnerHTML={{ __html: spotifyEmbed }} />
              </section>
            </Element>
          ) : (<></>)}


        <Element name="section-services">
          <section className=" padding-20">
            <SectionHeading color={$primaryFontColor} title="WHO WE PREFER" spacer={"40"} />
            <StatsLinks
              cardColor={$cardBackgroundColor}
              fontColor={$secondaryFontColor}
              partnerLogo1={athleteData.partnerLogo1}
              partnerTitle1={athleteData.partnerName1}
              partnerUrl1={athleteData.partnerUrl1}
              partnerLogo2={athleteData.partnerLogo2}
              partnerTitle2={athleteData.partnerName2}
              partnerUrl2={athleteData.partnerUrl2}
              partnerLogo3={athleteData.partnerLogo3}
              partnerTitle3={athleteData.partnerName3}
              partnerUrl3={athleteData.partnerUrl3}
              partnerLogo4={athleteData.partnerLogo4}
              partnerTitle4={athleteData.partnerName4}
              partnerUrl4={athleteData.partnerUrl4}
            />
          </section>
        </Element>

        <Element name="section-skills">
          <section className="padding-20" style={{ textAlign: "center" }}>
            <ReviewForm
              email={athleteData.email}
              cardBackgroundColor={$cardBackgroundColor}
              textColor={$primaryFontColor}
              mynarrativedata={athleteData}
            />
            <ContactForm
              email={athleteData.email}
              mynarrativedata={athleteData}
            />
          </section>
        </Element>
        <Element name="section-skills">
          <section className="padding-20" style={{ textAlign: "center" }}>
            <a href="https://mynarrative.com/" target="_blank">
              <img src={headerData.imageThumb} width={250} />
            </a>
          </section>
        </Element>

      </Layout>
    </>
  );
}


export default Homepage;
