function LogoComponent({businessLogo, backgroundColor}) {
    const containerStyle = {
      backgroundColor: backgroundColor,
      width: '150px', // Fixed size for the container
      height: '150px', // Fixed size for the container
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      position: 'relative'
    };
  
    const imageStyle = {
      width: '70%', // Ensure the image covers the container
      height: '70%', // Ensure the image covers the container
      objectFit: 'scale-down'
    };
return(
<section
className="d-flex align-items-center"
style={{backgroundColor:"transparent !important", marginTop: 0}}
>
      <div className="cta mx-auto mt-2" style={containerStyle}>
    <img src={businessLogo} style={imageStyle} width={200} />
  </div>
</section>)
}
export default LogoComponent