import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    partialVisibilityGutter: 40,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
  },
};

const padDescription = (description, targetLength) => {
  const paddingLength = targetLength - description.length;
  if (paddingLength > 0) {
    return description + "\u200B".repeat(paddingLength);
  }
  return description;
};

const Card = ({ image, title, description, cardBackgroundColor, secondaryFontColor, generalTextColor }) => (
  <div className="card" style={{
    borderRadius: 20,
    overflow: 'hidden',
    backgroundColor: cardBackgroundColor,
    width: '90%',  // Adjust based on your layout needs
    margin: '0 auto',  // Center the card horizontally
  }}>
    <div style={{
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      height: '200px',
    }}>
      {/* Empty div for the background image */}
    </div>
    <div style={{
      padding: 20,
      textAlign: 'center'
    }}>
      <h3 style={{ color: secondaryFontColor, fontSize: 18, textTransform:"uppercase" }}>{title}</h3>
      <p style={{ color: generalTextColor, fontSize: 14, fontWeight:200, lineHeight: 1.2, textAlign: "left" }}>{description}</p>
    </div>
  </div>
);

const ServicesCarousel = ({ athleteData, cardBackgroundColor, secondaryFontColor, generalTextColor }) => {
  const [maxCharCount, setMaxCharCount] = useState(0);

  useEffect(() => {
    const descriptions = [
      athleteData.serviceDescription1 || "",
      athleteData.serviceDescription2 || "",
      athleteData.serviceDescription3 || "",
      // Add more descriptions as needed
    ];
    const maxLength = Math.max(...descriptions.map(desc => desc.length));
    setMaxCharCount(maxLength);
  }, [athleteData]);

  const descriptionsPadded = [
    padDescription(athleteData.serviceDescription1 || "", maxCharCount),
    padDescription(athleteData.serviceDescription2 || "", maxCharCount),
    padDescription(athleteData.serviceDescription3 || "", maxCharCount),
  ];

  return (
    <Carousel
      responsive={responsive}
      swipeable={true}
      showDots={false}
      infinite={true}
      removeArrowOnDeviceType={["tablet", "mobile", "desktop", "superLargeDesktop"]}
      renderDotsOutside={false}
      itemClass="carousel-item-padding-40-px"
      partialVisible={true}
    >
      <Card
        image={athleteData.serviceImage1}
        title={athleteData.serviceTitle1}
        description={descriptionsPadded[0]}
        cardBackgroundColor={cardBackgroundColor}
        secondaryFontColor={secondaryFontColor}
        generalTextColor={generalTextColor}
      />
      <Card
        image={athleteData.serviceImage2}
        title={athleteData.serviceTitle2}
        description={descriptionsPadded[1]}
        cardBackgroundColor={cardBackgroundColor}
        secondaryFontColor={secondaryFontColor}
        generalTextColor={generalTextColor}
      />
      <Card
        image={athleteData.serviceImage3}
        title={athleteData.serviceTitle3}
        description={descriptionsPadded[2]}
        cardBackgroundColor={cardBackgroundColor}
        secondaryFontColor={secondaryFontColor}
        generalTextColor={generalTextColor}
      />
      {/* Add more cards as needed */}
    </Carousel>
  );
};

export default ServicesCarousel;
