import React from "react";
import { FaFacebookF, FaInstagram, FaTiktok, FaXTwitter, FaLocationDot } from "react-icons/fa6";
import LogoComponent from "../Items/Logo";

function HeroSection({businessName, description, businessAddress, businessAddress1, businessLogo, facebookUrl, twitterUrl, instagramProfileUrl, tiktokProfileUrl, businessEmail, businessPhone, primaryFontColor, backgroundColor}) {
  
  return (
    <>
    <LogoComponent 
      backgroundColor={backgroundColor}
      businessLogo={businessLogo}
    />
    <section
      className="hero parallax d-flex align-items-center"
      style={{backgroundColor:"transparent !important"}}
    >

        
      <div className="cta mx-auto mt-3">
      <p style={{fontSize:24, lineHeight:0.8, textAlign:"center", textTransform:"uppercase", fontWeight: 600, color: primaryFontColor}}>{businessName} <br />
        <br /><span style={{fontSize:18, fontWeight: 200}}>{description}</span></p>
        <div className="footer mt-auto mt-2">
            <ul className="social-icons list-inline " style={{textAlign:"center", fontSize:18, color: primaryFontColor}}>
              {!facebookUrl ? null : (
                <li style={{width:30}} className="list-inline-item">
                  <a href={`https://www.facebook.com/${facebookUrl}`}>
                    <FaFacebookF color={primaryFontColor} />
                  </a>
                </li>
              )}
              {!twitterUrl ? null : (
                <li style={{width:30}} className="list-inline-item">
                  <a href={`https://www.twitter.com/${twitterUrl}`}>
                    <FaXTwitter color={primaryFontColor} />
                  </a>
                </li>
              )}
              {!instagramProfileUrl ? null : (
                <li style={{width:30}} className="list-inline-item">
                  <a href={`https://www.instagram.com/${instagramProfileUrl}`}>
                    <FaInstagram color={primaryFontColor}/>
                  </a>
                </li>
              )}
              {!tiktokProfileUrl ? null : (
                <li style={{width:30}} className="list-inline-item">
                  <a href={tiktokProfileUrl}>
                    <FaTiktok color={primaryFontColor} />
                  </a>
                </li>
              )}

            </ul>
          </div>
      </div>
      <div className="overlay"></div>
    </section>
    </>
  );
}

export default HeroSection;
